import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("出库通知单号"),
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: T("状态"),
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_void ? T("已作废") : item.is_completed ? T("已完成") : T("进行中");
    },
  },
  {
    title: T("订单类型"),
    dataIndex: "order_type_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("往来单位单号"),
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("预计到货日期"),
    dataIndex: "arrival_date",
    width: 160,
    ellipsis: true,
  },
  {
    title: T("预计出库日期"),
    dataIndex: "delivery_date",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("往来单位"),
    dataIndex: "counterparty_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 300,
    ellipsis: true,
    scopedSlots: { customRender: "action" },
  },
];
